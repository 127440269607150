<script>
  import bannerTagImg1 from './imgs/24-BookOpenFill.png'
  import bannerTagImg2 from './imgs/24-UserFill.png'
  import bannerTagImg3 from './imgs/24-GridFill.png'
  import advantageImg1 from './imgs/part3_01.png'
  import advantageImg2 from './imgs/part3_02.png'
  import advantageImg3 from './imgs/part3_03.png'
  import advantageImg4 from './imgs/part3_04.png'
  import advantageImg5 from './imgs/part3_05.png'
  import advanceIcon1 from './imgs/24-HeartFill.png'
  import advanceIcon2 from './imgs/24-BookshelfFill.png'
  import advanceIcon3 from './imgs/ColumnFill.png'
  import advanceIcon4 from './imgs/24-GridFill(1).png'
  import customTagIcon1 from './imgs/Users.png'
  import customTagIcon2 from './imgs/Calendar.png'
  import customTagIcon3 from './imgs/EyeAlt.png'
  import customTagIcon4 from './imgs/16-ArrowRightFill.png'
  import customTagIcon5 from './imgs/VideoFill.png'
  import customTagIcon6 from './imgs/24-BookTextFill.png'
  import Anna from './imgs/teacher1.png'
  import Arain from './imgs/teacher2.png'
  import Daisy from './imgs/teacher3.png'
  import Lucy from './imgs/teacher4.png'
  import { mapState } from 'vuex'

  export default {
    data () {
      return {
        bannerTag: [
          {
            icon: bannerTagImg1,
            text: '10+年深耕留学线上培训'
          },
          {
            icon: bannerTagImg2,
            text: '资深名师团队'
          },
          {
            icon: bannerTagImg3,
            text: '专业课程服务'
          }
        ],
        advantageData: [
          {
            img: advantageImg1,
            title: '优势一 选择灵活',
            desc: '根据个人兴趣与发展方向从海量科目中选择最擅长的3至4门进行学习，更有利于学生发挥自己的特长，挖掘自己的潜力。',
            letter: 'A'
          },
          {
            img: advantageImg2,
            title: '优势二 好中择优',
            desc: 'A-Level 采用单元化、模块化的考试模式，每年有2至3次的考试，分布在5-6月、11-12月和1-2月。如有部分单元成绩不理想，可根据规定重考刷分。',
            letter: 'B'
          },
          {
            img: advantageImg3,
            title: '优势三 发挥优势',
            desc: '国内学生与外国同龄人相比，基础知识更扎实，数理化水平更强势，从国内课程转入A-Level也能更轻松入门。',
            letter: 'C'
          },
          {
            img: advantageImg4,
            title: '优势四 等级评分',
            desc: 'A-Level不采取百分制分数, 而是由高到低分为A*、A、B、C、D、E、U几个等级。考试轻微失误对分数等级的影响较小。',
            letter: 'D'
          },
          {
            img: advantageImg5,
            title: '优势五 无缝衔接',
            desc: '取得A-Level成绩后即可申请英联邦、美国、加拿大等地的大学的本科课程，无需参加入学考试或修读预科课程。',
            letter: 'E'
          }
        ],
        scoreData: [
          {
            level: 'A*',
            score: '90-100'
          },
          {
            level: 'A',
            score: '80-89'
          },
          {
            level: 'B',
            score: '70-79'
          },
          {
            level: 'C',
            score: '60-69'
          },
          {
            level: 'D',
            score: '50-59'
          },
          {
            level: 'E',
            score: '40-49'
          },
          {
            level: 'U',
            score: '40以下'
          }
        ],
        requirementData: [
          {
            barColor: '#FF9191',
            bgColor: '#FFF3F3',
            school: '牛津大学、剑桥大学等',
            require: '要求：A*A*A*，通常需要学习4科',
            level: '一'
          },
          {
            barColor: '#FFBE6E',
            bgColor: '#FBF2E3',
            school: '帝国理工学院、伦敦政经学院、伦敦大学学院等',
            require: '要求：A*A*A*-A*AA，通常需要学习3科',
            level: '二'
          },
          {
            barColor: '#BEE8BC',
            bgColor: '#E9FBE3',
            school: '爱丁堡大学、曼彻斯特大学、伦敦大学、国王学院等其他英国TOP10大学',
            require: '要求：A*AA-AAB，通常要求学习3科',
            level: '三'
          }, {
            barColor: '#A6D2F4',
            bgColor: '#E3F5FB',
            school: '英国TOP20大学',
            require: '要求：AAA-BBB',
            level: '四'
          },
          {
            barColor: '#BFC3FF',
            bgColor: '#F2F3FF',
            school: '英国TOP50大学',
            require: '要求：AAB-CCC',
            level: '五'
          }
        ],
        advanceType: 1,    // 1：选课建议 2： 热门选课组合
        advanceData: [
          {
            icon: advanceIcon1,
            title: '自身兴趣',
            desc: '选择自身擅长或喜好的科目，学习时更有自驱力与积极性，更容易达到预期的学习效果。'
          },
          {
            icon: advanceIcon2,
            title: '专业要求',
            desc: '查询目标院校官网，根据未来想要申请的专业要求来确定科目。避免因为科目受限而无法选择心仪的专业。'
          },
          {
            icon: advanceIcon3,
            title: '出分概率',
            desc: '中国学生一般选择参加CAIE，爱德思，AQA等考试局的考试。选择科目时可以参考不同考试局的不同科目的A*出分率，并选择更容易出分的学科。'
          },
          {
            icon: advanceIcon4,
            title: '精力分配',
            desc: '不同学科的考试方式会有区别，比如有些文科类学科考试时需要撰写文章，有些理科类学科需要进行实验测试。选课时需要量力而行，避免给自己太大压力。'
          }
        ],
        activeAdvanceIndex: 1,
        advantageContentIndex: 0,
        advanceCourseGroup: [
          {
            'type': '理工方向',
            'subjects': [
              { 'label': '数学', 'english': 'Mathematics' },
              { 'label': '进阶数学', 'english': 'Further Mathematics' },
              { 'label': '物理', 'english': 'Physics' }
              // ... 更多科目 ...
            ]
          },
          {
            'type': '医学方向',
            'subjects': [
              { 'label': '数学', 'english': 'Mathematics' },
              { 'label': '生物', 'english': 'Biology' },
              { 'label': '化学', 'english': 'Chemistry' }
              // ... 更多科目 ...
            ]
          },
          {
            'type': '计算机方向',
            'subjects': [
              { 'label': '数学', 'english': 'Mathematics' },
              { 'label': '物理', 'english': 'Physics' },
              { 'label': '计算机科学', 'english': 'Computer Science' }
              // ... 更多科目 ...
            ]
          },
          {
            'type': '经管方向',
            'subjects': [
              { 'label': '数学', 'english': 'Mathematics' },
              { 'label': '经济', 'english': 'Economics' },
              { 'label': '商务', 'english': 'Business' },
              { 'label': '统计', 'english': 'Statistics' }
              // ... 更多科目 ...
            ]
          },
          {
            'type': '文理方向',
            'subjects': [
              { 'label': '英语', 'english': 'English Language' },
              { 'label': '历史', 'english': 'History' },
              { 'label': '政治', 'english': 'Politics' }
              // ... 更多科目 ...
            ]
          },
          {
            'type': '文史方向',
            'subjects': [
              { 'label': '英国文学', 'english': 'English Literature' },
              { 'label': '历史', 'english': 'History' },
              { 'label': '哲学', 'english': 'Philosophy' }
              // ... 更多科目 ...
            ]
          }
        ],
        customTagList: [
          {
            icon: customTagIcon1,
            text: '名师授课'
          },
          {
            icon: customTagIcon2,
            text: '按需规划'
          },
          {
            icon: customTagIcon3,
            text: '全程监督'
          }
        ],
        customTagIcon6,
        customTagIcon5,
        customTagIcon4,
        activeTeacherIndex: '',
        teacherData: [
          {
            name: 'Anna',
            photo: Anna,
            subject: '数学',
            summary: '毕业于复旦大学，8年A-Level教学经验',
            desc: '毕业于复旦大学，8年A-Level教学经验<br><br>' +
              '善于总结，并用简洁的思路快速精准分析题目思路，在轻松愉快的氛围中帮助孩子攻克重点和难点<br><br>' +
              '根据每个学生的不同情况定制个性化的教学方案，挑选适合的练习材料，从不千篇一律<br><br>' +
              '教学案例：<br>' +
              '周*铭同学：11年级，42小时1V1后AS阶段取得A<br>' +
              '曾*旺同学：12年级，50小时1V1后取得A*\n'
          },
          {
            name: 'Arian',
            photo: Arain,
            subject: '数学',
            summary: '本科毕业于国内双一流985、211数学专业，硕士毕业于悉尼大学应用经济学商务数据分析专业',
            desc: '本科毕业于国内双一流985、211数学专业，硕士毕业于悉尼大学应用经济学商务数据分析专业<br><br>' +
              '3500+h授课时长，服务过多家知名机构，教学体系完整，能够一针见血发现学生问题，因材施教，解决学生痛点<br><br>' +
              '擅长讲解知识点配合考点习题，引用例子带学生理解知识点，简单输入，高效做题，稳定提分<br><br>' +
              '教学案例：<br>' +
              '朱*蔓同学：11年级，28小时1V1后取得A<br>' +
              '邱*瑜同学：12年级，40小时1V1后取得A*'
          },
          {
            name: 'Daisy',
            photo: Daisy,
            subject: '经济',
            summary: '本科毕业于国内双一流211经济专业，硕士毕业于新加坡国立大学经济学专业',
            desc: '本科毕业于国内双一流211经济专业，硕士毕业于新加坡国立大学经济学专业<br><br>' +
              '4000+h授课时长，长期保持教研，定期更新教学内容，对考试大纲、知识点内容、大考重点、题型均十分熟悉<br><br>' +
              '帮助学生更好理解微观经济和宏观经济的各个知识点，课程逻辑清晰，重点明确，擅长帮助学生查漏补缺，取得理想分数<br><br>' +
              '教学案例：<br>' +
              '刘*晗同学：11年级，30小时1V1后取得A<br>' +
              '田*强同学：12年级，42小时1V1后取得A*'
          },
          {
            name: 'Lucy',
            photo: Lucy,
            subject: '物理',
            summary: '6000+小时A-Level授课时长',
            desc: '6000+小时A-Level授课时长<br><br>' +
              '因材施教，课程针对性强、逻辑缜密，擅长将复杂的知识简单化，加深学生对物理现象的理解<br><br>' +
              '帮助学生构建一个系统的知识框架，培养批判性思维‌，更好地理解考试中物理的各个考点，逐一击破学生知识盲区<br><br>' +
              '教学案例：<br>' +
              '陈*嘉同学：12年级，55小时1V1后取得A*<br>' +
              '田*菲同学：12年级，30小时1V1后取得A'
          }
        ],
        isShowQrcodeDialog: false
      }
    },
    computed: {
      ...mapState({
        qrCode: state => {
          if (state.common.categoryTypes) {
            const target = state.common.categoryTypes.find(category => category.abbr === 'AL')
            return target.qrcode_image_url
          }
          return ''
        }
      })
    },
    methods: {
      handleChangeAdvance ( type ) {
        this.advanceType = type
      },
      addAnimation ( index ) {
        this.activeAdvanceIndex = index
      },
      removeAnimation () {
        this.activeAdvanceIndex = ''
      },
      addTeacherAnimation ( index ) {
        this.activeTeacherIndex = index
      },
      removeTeacherAnimation () {
        this.activeTeacherIndex = ''
      },
      handleService () {
        this.isShowQrcodeDialog = true
      },
      addAdvantage (index) {
        this.advantageContentIndex  = index
      },
      remoteAdvantage() {
        // this.advantageContentIndex =  ''
      }
    }
  }
</script>

<template>
  <div class="alevel-page">
    <div class="section-banner">
      <div class="banner-content section-content">
        <div class="logo">
          <div class="logo-zhi">
            <img src="./imgs/zhiedu_papa_logo_w.png" alt="">
          </div>
        </div>
        <div class="title">
          趴趴教育 A-Level培优
        </div>
        <div class="second-title">
          一站式定制教学 冲刺TOP名校
        </div>
        <div class="tag">
          <div class="tag-item" v-for="(item, index) in bannerTag" :key="index">
            <div class="item-icon">
              <img :src="item.icon" alt="">
            </div>
            <div class="item-text">{{ item.text }}</div>
          </div>
        </div>
        <div class="qr-code-banner">
          <div class="qr-code-img">
            <img :src="qrCode" alt="">
          </div>
          <div class="qr-code-text">
            A-Level 考培 + 语言培训 + 留学服务
            <br>
            扫码一站咨询
          </div>
        </div>
      </div>
    </div>
    <div class="section-summary">
      <div class="summary-content section-content">
        <div class="summary-title">
          <div class="title-content">
            A
            <div class="line"></div>
            Level
          </div>
          <div class="title-bg">

          </div>
        </div>
        <div class="summary-item">
          <b>A-Level（General Certificate of Education Advanced Level）</b>是英国的普通中等教育证书高
          <br>级水平课程，是英国学生的大学入学考试。
        </div>
        <div class="summary-item">
          A-Level成绩可以直接用于申请各国大学的本科，包括英国、美国、澳大利亚、加拿大、香港和
          <br>新加坡等国家的名校，是通往世界名校的“敲门砖”。
        </div>
      </div>

    </div>
    <div class="section-advantage">
      <div class="section-title">A-Level课程优势</div>
      <div class="section-content advantage-content">
        <div class="advantage-item" v-for="(item, index) in advantageData" :key="index"
             @mouseover="addAdvantage(index)"
             @mouseleave="remoteAdvantage()"
        >
          <div v-if="index % 2 === 0">
            <div class="item-text-wrapper" style="padding-bottom: 0">
              <div class="wrapper-title">{{ item.title }}</div>
              <div class="wrapper-text">{{ item.desc }}</div>
              <div class="wrapper-letter">{{item.letter}}</div>
            </div>
            <div class="item-image-wrapper" style="padding-top: 0">
              <img :src="item.img" alt="">
            </div>
          </div>
          <div v-else>
            <div class="item-image-wrapper" style="padding-top: 0">
              <img :src="item.img" alt="">
            </div>
            <div class="item-text-wrapper" style="padding-bottom: 0">
              <div class="wrapper-title">{{ item.title }}</div>
              <div class="wrapper-text">{{ item.desc }}</div>
              <div class="wrapper-letter">{{item.letter}}</div>
            </div>
          </div>
          <div :class="[advantageContentIndex === index ? 'item-hover': 'item-hover-none', index % 2 === 0 ? 'item-hover-odd' : 'item-hover-even']">
            <div class="item-text-wrapper" style="padding-bottom: 0" v-if="index %2 ===0">
              <div class="wrapper-title">{{ item.title }}</div>
              <div class="wrapper-text">{{ item.desc }}</div>
              <div class="wrapper-letter">{{item.letter}}</div>
            </div>
            <div class="item-image-wrapper" style="padding-top: 0"></div>
            <div class="item-text-wrapper" style="padding-bottom: 0" v-if="index %2 !==0">
              <div class="wrapper-title">{{ item.title }}</div>
              <div class="wrapper-text">{{ item.desc }}</div>
              <div class="wrapper-letter">{{item.letter}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-requirements">
      <div class="section-title">英国名校成绩要求</div>
      <div class="requirements-content section-content">
        <div class="require-table">
          <table cellspacing="0" style="border-collapse: collapse; width: 100%;">
            <thead style="background: #29d087; color: #fff;">
            <tr>
              <th style="border: 1px solid #29d087; padding: 8px;">A-level等级</th>
              <th style="border: 1px solid #29d087; padding: 8px;">对应的百分制分数</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in scoreData" :key="index">
              <td style="border: 1px solid #B9E7D3; padding: 8px; color: #29d087;font-weight: bold">{{
                  item.level
                }}
              </td>
              <td style="border: 1px solid #B9E7D3; padding: 8px;;font-weight: bold; color: rgba(0, 0, 0, 0.5);">
                {{ item.score }}
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="2" style="border: 1px solid #B9E7D3; padding:11px 20px;">
                A为优秀，E为及格，U为不及格。A-level分为AS、A2两个考试阶段。在AS阶段，A对应的分数段为80-100分。完成AS与A2后，取得A-Level最终成绩才会有A*等级。
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
        <div class="require-ladder">
          <div class="ladder-item" v-for="(item, index) in requirementData" :key="index"
               :style="{width: 63+ index*10 + '%' }">
            <div class="item-content">
              <div class="content-bar" :style="{background: item.barColor}"></div>
              <div class="content-content" :style="{background: item.bgColor}">
                <div class="content-school">{{ item.school }}</div>
                <div class="content-require">{{ item.require }}</div>
              </div>
            </div>
            <div class="item-level">第{{ item.level }}梯队</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-advance">
      <div class="section-title">选课指南</div>
      <div class="advance-content section-content">
        <div class="tab-title">
          <div class="tab-item" :class="advanceType === 1? 'tab-item-active':''" @click="handleChangeAdvance(1)">
            选课建议
          </div>
          <div class="tab-item" :class="advanceType === 2? 'tab-item-active':''" @click="handleChangeAdvance(2)">
            热门选课组合
          </div>
        </div>
        <div class="tab-content">
          <div class="content-item" v-if="advanceType === 1">
            <div class="advance-item" v-for="(item, index) in advanceData" :key="index"
                 @mouseover="addAnimation(index)"
                 @mouseleave="removeAnimation"
                 :class="activeAdvanceIndex === index? 'slideInUp':''"

            >
              <div class="item-bg">
                <div class="item-index">0{{ index + 1 }}</div>
                <div class="item-icon">
                  <img :src="item.icon" alt="">
                </div>
              </div>
              <div class="item-content">
                <div class="item-title">{{ item.title }}</div>
                <div class="item-desc">{{ item.desc }}</div>
              </div>
            </div>
          </div>
          <div class="content-item" v-if="advanceType === 2">
            <div class="major-item" v-for="(item, index) in  advanceCourseGroup" :key="index"
                 @mouseover="addAnimation(index)"
                 @mouseleave="removeAnimation"
                 :class="activeAdvanceIndex === index? 'slideInUp':''"
            >
              <div class="major-title">
                <div class="title-index">0{{ index + 1 }}</div>
                <div class="title-title">{{ item.type }}</div>
              </div>
              <div class="subjects">
                <div class="subject-item" v-for="(subject, index) in item.subjects" :key="index">
                  <div class="item-label">{{ subject.label }}</div>
                  <div class="item-english">{{ subject.english }}</div>
                </div>
              </div>
              <div class="subject-more">......</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-custom">
      <div class="section-title">趴趴教育 A-Level 1V1定制课程</div>
      <div class="section-content custom-content">
        <div class="tag-list">
          <div class="tag-item" v-for="(tag, index) in customTagList" :key="index">
            <div class="tag-icon">
              <img :src="tag.icon" alt="">
            </div>
            <div class="tag-text">{{ tag.text }}</div>
          </div>
        </div>
        <div class="content-list">
          <div class="content-item">
            <div class="item-title">
              课程内容定制化，内容适配各考试局
            </div>
            <div class="item-content">
              根据测试暴露问题，真正做到个性化授课<br>
              科学课程安排，灵活课程时间<br>
              开设科目：数学、物理、化学、经济、商务、会计......
            </div>
            <div class="item-more" @click="handleService">
              更多科目可咨询 <img :src="customTagIcon4" alt="">
            </div>
          </div>
          <div class="content-item">
            <div class="item-title">课上+课下全方位教学模式 保障学习效果</div>
            <div class="item-list">
              <div class="point">
                <div class="left">
                  <img :src="customTagIcon5" alt="">
                </div>
                <div class="right">
                  <div class="point-title">专业平台直播上课</div>
                  <div class="point-content">趴趴APP/官网直播上课，有效期内无限次回放，随时精看课程</div>
                </div>

              </div>
              <div class="point">
                <div class="left">
                  <img :src="customTagIcon6" alt="">
                </div>
                <div class="right">
                  <div class="point-title">课下答疑全面监督</div>
                  <div class="point-content">任课老师课下答疑 助教老师在线监督 家长无忧</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-teacher">
      <div class="section-title">大咖云集</div>
      <div class="section-second-title">- 以下仅展示部分师资 -</div>
      <div class="section-content">
        <div class="teacher-list">
          <div class="teacher-item" v-for="(teacher, index) in teacherData" :key="index"
               @mouseover="addTeacherAnimation(index)"
               @mouseleave="removeTeacherAnimation"
          >
            <div class="teacher-photo">
              <img :src="teacher.photo" alt="">
            </div>
            <div class="teacher-bar"
                 :class="activeTeacherIndex === index? 'opacityAni':''"
            >
              <div class="teacher-name">{{ teacher.name }} <span class="teacher-subject">{{ teacher.subject }}</span>
              </div>
              <div class="teacher-summary">{{ teacher.summary }}</div>
            </div>
            <div class="teacher-mask"
                 :class="activeTeacherIndex === index? 'slideInTeacherUp':''"
            >
              <div class="teacher-name">{{ teacher.name }} <span class="teacher-subject">{{ teacher.subject }}</span>
              </div>
              <div class="teacher-desc" v-html="teacher.desc"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-service">
      <div class="service-code">
        <img :src="qrCode" alt="">
      </div>
      <div class="service-test">扫码立即咨询</div>
    </div>
    <el-dialog
      :visible.sync="isShowQrcodeDialog"
      style="border-radius: 8px"
    >
      <div class="service-code">
        <img :src="qrCode" alt="">
        <div class="service-test">扫码立即咨询</div>
      </div>
    </el-dialog>
  </div>

</template>

<style scoped lang="less">
  .alevel-page {
    width: 100%;

    .section-service {
      width: 100%;
      height: 230px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: url("./imgs/bg-part7.png") no-repeat center center;
      background-size: cover;

      .service-code {
        width: 120px;
        height: 120px;
        border-radius: 8px;
        background: #fff;
        margin-bottom: 8px;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .service-test {
        color: rgb(255, 255, 255);
        text-align: center;
        font-size: 14px;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .section-teacher {
      height: 534px;
      background: #fff;

      .section-second-title {
        color: rgba(0, 0, 0, 0.6);
        text-align: center;
        font-family: "PingFang SC";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
        margin-top: -16px;
      }

      .teacher-list {
        display: flex;
        justify-content: space-between;
        text-align: left;

        .teacher-item {
          position: relative;
          border-radius: 12px;
          overflow: hidden;

          .teacher-photo {
            width: 285px;
            height: 360px;

            img {
              width: 100%;
            }
          }

          .teacher-bar {
            transition: all .2s linear;
            box-sizing: border-box;
            padding: 10px 20px;
            height: 62px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #29d087f2;
            color: #fff;
            opacity: 1;

            .teacher-name {
              font-family: "PingFang SC";
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              display: flex;
              align-items: center;
            }

            .teacher-subject {
              height: 18px;
              padding: 0 6px;
              justify-content: center;
              align-items: center;
              border-radius: 20px;
              background: #ffffff33;
              font-family: "PingFang SC";
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              margin-left: 8px;
            }

            .teacher-summary {
              height: 15px;
              align-self: stretch;
              overflow: hidden;
              color: #ffffff;
              font-size: 12px;
              text-overflow: ellipsis;
              font-family: "PingFang SC";
            }
          }

          .opacityAni {
            bottom: 0px;
            height: 122px;
            opacity: 0;
          }

          .teacher-mask {
            transition: all .2s linear;
            box-sizing: border-box;
            padding: 20px;
            position: absolute;
            top: 360px;
            left: 0;
            width: 100%;
            height: 360px;
            flex-shrink: 0;
            opacity: 0;
            background: rgba(0, 0, 0, 0.78);

            .teacher-name {
              font-weight: 600;
              color: #29d087;
              border-bottom: 1px solid #29d087;
              padding-bottom: 12px;
              margin-bottom: 12px;
              display: flex;
              align-items: center;
            }

            .teacher-subject {
              height: 18px;
              padding: 0 6px;
              justify-content: center;
              align-items: center;
              border-radius: 20px;
              background: rgba(41, 208, 135, 0.1);
              font-family: "PingFang SC";
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              margin-left: 8px;
            }

            .teacher-desc {
              color: #fff;
              font-size: 12px;
            }
          }

          .slideInTeacherUp {
            opacity: 1;
            top: 0
          }
        }
      }
    }

    .section-custom {
      .custom-content {
        height: 270px;

        .tag-list {
          display: flex;
          justify-content: center;
          width: 100%;

          .tag-item {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            height: 36px;
            padding: 0 18px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 18px;
            border: 1px solid #29D087;
            background: rgba(41, 208, 135, 0.08);
            margin-right: 12px;

            .tag-icon {
              width: 16px;
              height: 16px;
              flex-shrink: 0;
              font-size: 0;

              img {
                width: 100%;
              }
            }

            .tag-text {
              color: rgb(41, 208, 135);
              text-align: center;
              font-family: "PingFang SC";
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }

        .content-list {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;

          .content-item {
            background: #fff;
            width: 590px;
            height: 182px;
            flex-shrink: 0;
            border-radius: 12px;
            box-shadow: 0 2px 16px 0 rgba(85, 85, 85, 0.08);

            .item-title {
              box-sizing: border-box;
              display: flex;
              width: 590px;
              height: 48px;
              padding: 10px 20px;
              align-items: flex-end;
              flex-shrink: 0;
              border-radius: 12px 12px 0 12px;
              background: #29D087;
              color: rgb(255, 255, 255);
              font-family: "PingFang SC";
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            .item-content {
              box-sizing: border-box;
              padding: 20px 20px 8px;
              color: rgba(0, 0, 0, 0.9);
              font-family: "PingFang SC";
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-align: left;
            }

            .item-more {
              display: inline-flex;
              float: left;
              margin-left: 20px;
              height: 26px;
              padding: 0 13px;
              justify-content: center;
              align-items: center;
              gap: 2px;
              flex-shrink: 0;
              border-radius: 14px;
              background: rgba(41, 208, 135, 0.08);
              color: rgb(41, 208, 135);
              text-align: center;
              font-family: "PingFang SC";
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              cursor: pointer;

              img {
                width: 14px;
              }
            }

            .item-list {
              box-sizing: border-box;
              padding: 20px 24px;

              .point {
                display: flex;
                align-items: center;
                text-align: left;
                margin-bottom: 10px;

                .point-title {
                  color: rgba(0, 0, 0, 0.9);
                  font-family: "PingFang SC";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }

                .point-content {
                  color: rgba(0, 0, 0, 0.7);
                  font-family: "PingFang SC";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .left {
                  width: 32px;
                  margin-right: 10px;
                  font-size: 0;

                  img {
                    width: 100%;
                  }
                }
              }
            }
          }

        }
      }
    }

    .section-content {
      width: 1200px;
      margin: 0px auto;
    }

    .section-title {
      width: 100%;
      color: #000000e6;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 40px 0 20px;
    }

    .section-banner {
      width: 100%;
      height: 400px;
      text-align: center;
      background: url("./imgs/banner-bg.png") no-repeat center center;
      background-size: cover;

      .banner-content {
        color: #ffffff;
        box-sizing: border-box;
        padding: 50px 60px;
        text-align: left;
      }

      .logo {
        width: 216px;
        margin-bottom: 40px;
        font-size: 0;
        img {
          width: 100%;
        }
      }

      .title {
        font-family: "PingFang SC";
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        margin-bottom: 8px;
      }

      .second-title {
        font-family: "PingFang SC";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .tag {
        display: flex;
        margin-bottom: 24px;

        .tag-item {
          display: flex;
          margin-right: 8px;
          padding: 2px 12px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 20px;
          background: #ffffff26;

          .item-icon {
            width: 12px;
            font-size: 0;

            img {
              width: 100%;
            }
          }

          .item-text {
            color: #ffffff;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }

      .qr-code-banner {
        display: flex;
        align-items: center;

        .qr-code-img {
          width: 84px;
          height: 84px;
          margin-right: 22px;
          border-radius: 4px;
          background: #fff;
          overflow: hidden;
          font-size: 0;

          img {
            width: 100%;
          }
        }

        .qr-code-text {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .section-summary {
      box-sizing: border-box;
      width: 100%;
      height: 234px;
      padding: 40px 0;
      background: url("./imgs/part2_bg.png") no-repeat center center;
      background-size: cover;
      text-align: left;

      .summary-content {

      }

      .summary-title {
        color: rgba(0, 0, 0, 0.9);
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 25px;
        position: relative;
        display: flex;
        align-items: center;
        height: 24px;

        .title-content {
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          gap: 4px;
          z-index: 2;
        }

        .line {
          width: 10px;
          height: 2px;
          padding: 0 3px;
          background: rgba(0, 0, 0, 0.9);
        }
      }

      .title-bg {
        position: absolute;
        left: 15px;
        top: 3px;
        width: 136px;
        height: 24px;
        background: linear-gradient(90deg, rgba(41, 208, 135, 0.40) 0%, rgba(41, 208, 135, 0.00) 100%);
        z-index: 1;
      }

      .summary-item {
        padding-left: 10px;
        margin-bottom: 12px;
        position: relative;
      }

      .summary-item::before {
        position: absolute;
        left: -4px;
        top: 8px;
        content: "";
        width: 8px;
        height: 8px;
        background: #29d087;
        border-radius: 50%;

      }
    }

    .section-advantage {
      background: #fff;
      box-sizing: border-box;
      padding: 0 40px 40px;

      .advantage-content {
        border-radius: 12px;
        overflow: hidden;
        display: flex;

        .advantage-item {
          box-sizing: border-box;
          width: 240px;
          position: relative;

          .item-text-wrapper {
            height: 220px;
            padding: 20px;
            box-sizing: border-box;
            text-align: left;
            position: relative;
            overflow: hidden;
            .wrapper-title {
              margin-bottom: 12px;
              font-size: 20px;
              color: rgba(0, 0, 0, 0.9);
              font-weight: bold;
            }

            .wrapper-text {
              text-align: left;
              font-size: 16px;
              text-align: justify;
            }
            .wrapper-letter{
              position: absolute;
              right: -10px;
              top: 90px;
              color: #29d0870d;
              text-align: right;
              font-family: "Ubuntu";
              font-size: 130px;
              font-style: normal;
              font-weight: 700;
            }
          }

          .item-image-wrapper {
            height: 220px;
            font-size: 0;
            img {
              width: 100%;
            }
          }
        }

        .item-hover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .item-text-wrapper {
            color: #fff;
            overflow: inherit;

            .wrapper-title {
              color: #fff;
            }
            .wrapper-letter{
              color: rgba(255, 255, 255, 0.1);
              top: 100px;
            }
          }
        }

        .item-hover-even {
          .item-text-wrapper {
            display: block;
          }

          background: linear-gradient(180deg, rgba(41, 208, 135, 0.20) 0%, #29D087 50%);
        }

        .item-hover-odd {
          .item-text-wrapper {
            display: block;
          }

          background: linear-gradient(180deg, #29D087 50%, rgba(41, 208, 135, 0.20) 100%);
        }
        .item-hover-none{
          display: none;
        }
      }
    }

    .section-requirements {
      padding-bottom: 40px;

      .requirements-content {
        display: flex;
        align-items: center;

        .require-table {
          width: 468px;
          border-radius: 12px;
          overflow: hidden;
          border-radius: 12px;
          border: 2px solid #29D087;
          box-shadow: 0 2px 12px 0 rgba(0, 184, 103, 0.2);
          margin-right: 40px;

          tfoot {
            background: rgba(41, 208, 135, 0.05);
            color: rgba(0, 0, 0, 0.7);
            font-size: 14px;
            text-align: left;
          }

          td:nth-child(odd) {
            border-left: none !important;
          }

          td:nth-child(even) {
            border-right: none !important;
          }

          td:last-child {
            border-bottom: none !important;
            border-right: none !important;
          }
        }

        .require-ladder {
          flex: 1;

          .ladder-item {
            width: 100%;
            display: flex;
            margin-bottom: 13px;
            font-size: 14px;
            text-align: left;
            align-items: center;

            .item-content {
              display: flex;
              flex: 1;
              margin-right: 16px;

              .content-bar {
                width: 8px;
                height: 68px;
              }

              .content-content {
                flex: 1;
                padding: 12px;
                border-radius: 0 0 12px 0;

                .content-school {
                  font-weight: 500;
                  margin-bottom: 4px;
                }
              }
            }

            .item-level {
              color: #000000e6;
              font-family: "PingFang SC";
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

          }

          .ladder-item:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .section-advance {
      background: #fff;

      .advance-content {
        .tab-title {
          display: flex;
          align-items: end;

          .tab-item {
            box-sizing: border-box;
            width: 50%;
            height: 60px;
            text-align: center;
            color: #29D087;
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 20px 0;
            display: flex;
            padding: 16px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 12px 12px 0 0;
            background: #E5F5EE;
            margin-right: 8px;
            cursor: pointer;
          }

          .tab-item:hover {
            transition: all .3s linear;
            height: 78px;
            background: #E5F5EE;
            color: #29d087;
            font-size: 24px;
            font-weight: 500;
          }

          .tab-item-active {
            font-weight: 500;
            height: 78px;
            font-size: 24px;
            background: #29d087 !important;
            color: #fff !important;
          }

          .tab-item:last-child {
            margin-right: 0;
          }
        }

        .tab-content {
          .content-item {
            padding: 32px 24px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background: linear-gradient(180deg, #F7F8FA 50%, #f7f8fa00 100%);

            .advance-item {
              transition: all .2s linear;
              top: 0;
              width: 564px;
              height: 132px;
              margin-bottom: 20px;
              flex-shrink: 0;
              border-radius: 12px;
              background: var(--Frame-99A-MapUIFrame99A, #FFF);
              box-shadow: 0 2px 16px 0 #55555514;
              position: relative;

              .item-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .item-index {
                  position: absolute;
                  left: 86px;
                  top: 12px;
                  color: #eafaf3;
                  font-family: "DINOT";
                  font-size: 52px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  font-style: italic;
                }

                .item-icon {
                  position: absolute;
                  right: 22px;
                  top: 0px;
                  width: 88px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  font-size: 0;
                  img {
                    width: 100%;
                  }
                }
              }

              .item-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 28px 24px;
                text-align: left;
                cursor: pointer;

                .item-title {
                  width: 100%;
                  color: rgba(0, 0, 0, 0.9);
                  font-family: "PingFang SC";
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  margin-bottom: 8px;
                }

                .item-desc {
                  color: rgba(0, 0, 0, 0.7);
                  font-family: "PingFang SC";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }

            .major-item {
              transition: all .2s linear;
              top: 0;
              box-sizing: border-box;
              padding: 20px;
              width: 376px;
              height: 188px;
              margin-bottom: 20px;
              flex-shrink: 0;
              border-radius: 12px;
              background: var(--Frame-99A-MapUIFrame99A, #FFF);
              box-shadow: 0 2px 16px 0 rgba(85, 85, 85, 0.16);
              cursor: pointer;
              position: relative;

              .major-title {
                width: 100%;
                position: relative;
                text-align: center;
                height: 40px;
                display: flex;
                justify-content: center;

                .title-index {
                  position: absolute;
                  top: -8px;
                  left: 50%;
                  margin-left: -20px;
                  color: rgba(41, 208, 135, 0.1);
                  text-align: center;
                  font-family: "PingFang SC";
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 40px;
                }

                .title-title {
                  color: rgba(0, 0, 0, 0.9);
                  text-align: center;
                  font-family: "PingFang SC";
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                }
              }

              .subjects {
                display: flex;
                justify-content: space-between;

                .subject-item {
                  box-sizing: border-box;
                  width: 109px;
                  display: flex;
                  height: 74px;
                  padding: 8px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 2px;
                  border-radius: 8px;
                  background: #F0F8F5;
                  margin-right: 5px;

                  .item-label {
                    color: rgba(0, 0, 0, 0.9);
                    text-align: center;
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }

                  .item-english {
                    color: rgba(0, 0, 0, 0.6);
                    text-align: center;
                    font-family: "PingFang SC";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                  }
                }

                .subject-item:last-child {
                  margin-right: 0;
                }
              }
            }

            .major-item:last-child {
              margin-right: 0;
            }

            .slideInUp {
              top: -4px;
              box-shadow: 0px 2px 16px 0px rgba(85, 85, 85, 0.16);
            }
          }
        }
      }
    }

    :deep(.el-dialog) {
      border-radius: 8px;
      width: 300px;
      height: 300px;

      .el-dialog__body {
        padding: 10px 0;
      }

      img {
        width: 70%;
      }
    }
  }
</style>
